import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Departments = lazy(() => import('./departments/Departments'));

const DepartmentAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'department',
      element: <Departments />,
      auth: ["viewDepartment"]
    },
    {
      path: 'department/:departmentId',
      element: <Departments />,
      auth: ["addDepartment", "editDepartment"],
    },
    {
      path: 'department',
      element: <Navigate to="department" />,
    },
  ],
};

export default DepartmentAppConfig;
