import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Product = lazy(() => import('./newGallery/NewGallery'));
const Gallery = lazy(() => import('./gallery/Gallery'));


const GalleryAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'gallery',
      element: <Gallery />,
      auth: ["viewGallery"],
    },
    {
      path: 'gallery/:galleryId/*',
      element: <Product />,
      auth: ["addGallery", "editGallery"],
    },
    {
      path: 'gallery',
      element: <Navigate to="gallery" />,
    },
  ],
};

export default GalleryAppConfig;
