import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const PaymentMethods = lazy(() => import('./paymentMethods/PaymentMethods'));

const PaymentMethodAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/payment-method',
      element: <PaymentMethods />,
      auth: ["viewPaymentMethod"]
    },
    {
      path: 'setting/payment-method/:paymentMethodId',
      element: <PaymentMethods />,
      auth: ["addPaymentMethod", "editPaymentMethod"],
    },
    {
      path: 'payment-method',
      element: <Navigate to="payment-method" />,
    },
  ],
};

export default PaymentMethodAppConfig;
