import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const TurnaroundTimes = lazy(() => import('./turnaroundTimes/TurnaroundTimes'));

const TurnaroundTimeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/turnaround-times',
      element: <TurnaroundTimes />,
      auth: ["viewTrunaroundTime"]
    },
    {
      path: 'setting/turnaround-times/:turnaroundTimeId',
      element: <TurnaroundTimes />,
      auth: ["addTrunaroundTime", "editTrunaroundTime"],
    },
    {
      path: 'turnaround-times',
      element: <Navigate to="turnaround-times" />,
    },
  ],
};

export default TurnaroundTimeAppConfig;
