import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ChangePassword = lazy(() => import('./changePassword/ChangePassword'));


const ChangePasswordAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'change-password',
      element: <ChangePassword />,
    },
    {
      path: 'change-password',
      element: <Navigate to="change-password" />,
    },
  ],
};

export default ChangePasswordAppConfig;
