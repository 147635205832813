import ChangePasswordAppConfig from "./changePassword/changePasswordAppConfig";
import RolesAppConfig from "./roles/rolesAppConfig";
import OrderSettingsConfig from "./orderSettings/orderSettingsConfigs";
import PaymentMethodAppConfig from "./paymentMethod/PaymentMethodAppConfig";
import BasicSettingsConfigs from "./basicSettings/basicSettingsConfigs";


const SettingConfigs = [
  ChangePasswordAppConfig,
  RolesAppConfig,
  ...OrderSettingsConfig,
  PaymentMethodAppConfig,
  ...BasicSettingsConfigs,
];

export default SettingConfigs;
