import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const PatchEmbroideries = lazy(() => import('./patchEmbroideries/PatchEmbroideries'));

const PatchEmbroideryAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/patch-embroideries',
      element: <PatchEmbroideries />,
      auth: ["viewPatchEmbroidery"]
    },
    {
      path: 'setting/patch-embroideries/:patchEmbroideryId',
      element: <PatchEmbroideries />,
      auth: ["addPatchEmbroidery", "editPatchEmbroidery"],
    },
    {
      path: 'patch-embroideries',
      element: <Navigate to="patch-embroideries" />,
    },
  ],
};

export default PatchEmbroideryAppConfig;
