import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Admin = lazy(() => import('./admin/Admin'));
const SubAdmin = lazy(() => import('./subAdmin/SubAdmin'));
const Designer = lazy(() => import('./designer/Designer'));
const SEO = lazy(() => import('./seo/SEO'));




const DashboardAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'dashboard/admin',
      element: <Admin />,
      auth: ["adminDashboard"],
    },
    {
      path: 'dashboard/subadmin',
      element: <SubAdmin />,
      auth: ["subAdminDashboard"],
    },
    {
      path: 'dashboard/seo',
      element: <SEO />,
      auth: ["seoDashboard"],
    },
    {
      path: 'dashboard/designer',
      element: <Designer />,
      auth: ["designerDashboard"],
    },
    // {
    //   path: 'admin/dashboard',
    //   element: <Navigate to="dashboard" />,
    // },
  ],
};

export default DashboardAppConfig;
