import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const AddPricing = lazy(() => import('./addPricing/AddPricing'));
const Pricing = lazy(() => import('./pricing/Pricing'));


const PricingAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'pricing',
      element: <Pricing />,
      auth: ["viewPricing"],
    },
    {
      path: 'pricing/:pricingId/*',
      element: <AddPricing />,
      auth: ["addPricing", "editPricing"],
    },
    {
      path: 'pricing',
      element: <Navigate to="pricing" />,
    },
  ],
};

export default PricingAppConfig;
