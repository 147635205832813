import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Employee = lazy(() => import('./employee/Employee'));
const Employees = lazy(() => import('./employees/Employees'));
const Profile = lazy(() => import('./profile/Profile'));




const EmployeeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'employee',
      element: <Employees />,
      // auth: ["viewJob"],
    },
    {
      path: 'employee/:employeeId',
      element: <Employee />,
      // auth: ["addJob", "editJob"],
    },
    {
      path: 'employee/:employeeId/:secType',
      element: <Profile />,
      // auth: ["addJob", "editJob"],
    },
    {
      path: 'employee/:employeeId/:secType/:updateId',
      element: <Profile />,
      // auth: ["addJob", "editJob"],
    },
    {
      path: 'employee',
      element: <Navigate to="employee" />,
    },
  ],
};

export default EmployeeAppConfig;
