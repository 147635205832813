import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ReturnTimes = lazy(() => import('./returnTimes/ReturnTimes'));

const ReturnTimeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/return-time',
      element: <ReturnTimes />,
      auth: ["viewReturnTime"]
    },
    {
      path: 'setting/return-time/:returnTimeId',
      element: <ReturnTimes />,
      auth: ["addReturnTime", "editReturnTime"],
    },
    {
      path: 'return-time',
      element: <Navigate to="return-time" />,
    },
  ],
};

export default ReturnTimeAppConfig;
