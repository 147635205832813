import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import axios from 'axios';


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  password: yup
    .string()
    .required('New password is required')
    .min(6, 'Password must be at least 6 characters'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),

});


const defaultValues = {
  email: '',
  token: '',
  password: '',
  password_confirmation: ''
};

function ResetPassword() {

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { control, formState, handleSubmit, setError, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const [errorMsg, setErrorMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (searchParams.get("email") && searchParams.get("token")) {
      reset({
        email: searchParams.get("email"),
        token: searchParams.get("token")
      })
    } else {
      navigate("/sign-in")
    }
  }, []);

  function onSubmit(values) {
    setErrorMsg("")
    setIsLoading(true)
    axios.post("/api/v1/user/password/reset", values).then((res) => {
      navigate("/sign-in")
    }).catch((err) => {
      console.log("err", err)
      setErrorMsg(err?.response?.data?.message)
      setIsLoading(false)
    })
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow text-center">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="mx-auto" src="assets/images/logo/desopers-logo.png" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Reset Password
          </Typography>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                  disabled
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="password_confirmation"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Confirm Password"
                  type="password"
                  error={!!errors.password_confirmation}
                  helperText={errors?.password_confirmation?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            {errorMsg &&
              <p className='text-red-500'>{errorMsg}</p>
            }

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Reset Password"
              disabled={_.isEmpty(dirtyFields) || !isValid || isLoading}
              type="submit"
              size="large"
            >
              Reset Password
            </Button>

          </form>
        </div>
      </Paper>
    </div>
  );
}

export default ResetPassword;
