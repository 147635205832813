import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Role = lazy(() => import('./role/Role'));
const Roles = lazy(() => import('./roles/Roles'));


const RolesAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/roles',
      element: <Roles />,
      auth: ["viewRole"],
    },
    {
      path: 'setting/roles/:roleId/*',
      element: <Role />,
      auth: ["addRole", "editRole"],
    },
    {
      path: 'roles',
      element: <Navigate to="roles" />,
    },
  ],
};

export default RolesAppConfig;
