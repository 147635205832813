import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Designations = lazy(() => import('./designations/Designations'));

const DesignationAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'designation',
      element: <Designations />,
      auth: ["viewDesignation"]
    },
    {
      path: 'designation/:designationId',
      element: <Designations />,
      auth: ["addDesignation", "editDesignation"],
    },
    {
      path: 'designation',
      element: <Navigate to="designation" />,
    },
  ],
};

export default DesignationAppConfig;
