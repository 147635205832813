import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Warning = lazy(() => import('./warning/Warning'));
const Warnings = lazy(() => import('./warnings/Warnings'));
const WarningView = lazy(() => import('./warningView/WarningView'));



const WarningAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'warning',
      element: <Warnings />,
      // auth: ["viewWarning"],
    },
    {
      path: 'warning/:warningId/*',
      element: <Warning />,
      // auth: ["addWarning", "editWarning"],
    },
    {
      path: 'warning/view/:warningId/*',
      element: <WarningView />,
      // auth: ["viewWarningView"],
    },
    {
      path: 'warning',
      element: <Navigate to="warning" />,
    },
  ],
};

export default WarningAppConfig;
