import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import jwtService from '../../../auth/services/jwtService';
import axios from 'axios';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
});


const defaultValues = {
  email: '',
};

function ForgotPassword() {

  const { control, formState, handleSubmit, setError, setValue, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const emailValue = watch("email")

  const [errorMsg, setErrorMsg] = useState("")
  const [resMsg, setResMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (emailValue && resMsg) {
      setResMsg("")
    }
  }, [emailValue]);


  function onSubmit({ email }) {
    setErrorMsg("")
    setResMsg("")
    setIsLoading(true)
    axios.post("/api/v1/user/password/forgot", {
      email: email
    }).then((res) => {
      reset()
      setResMsg(res.data.message)
      setIsLoading(false)
    }).catch((err) => {
      console.log("err", err)
      setErrorMsg(err?.response?.data?.errors)
      setIsLoading(false)
    })
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow text-center">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="mx-auto" src="assets/images/logo/desopers-logo.png" alt="logo" />

          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Forgot Password
          </Typography>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-10"
                  label="Email"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
            {resMsg &&
              <p className='text-green-500'>{resMsg}</p>
            }
            {errorMsg && errorMsg?.email && errorMsg?.email?.map((item, i) => (
              <p key={i} className='text-red-500'>{item}</p>
            ))
            }

            <Button
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              aria-label="Send Password Reset Link"
              disabled={_.isEmpty(dirtyFields) || !isValid || isLoading}
              type="submit"
              size="large"
            >
              Send Password Reset Link
            </Button>
          </form>
          <div className='mt-[20px]'>
            <Link to={"/sign-in"} >Login</Link>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default ForgotPassword;
