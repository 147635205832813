import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const EmbroideryLocations = lazy(() => import('./embroideryLocations/EmbroideryLocations'));

const EmbroideryLocationAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/embroidery-locations',
      element: <EmbroideryLocations />,
      auth: ["viewEmbroideryLocation"]
    },
    {
      path: 'setting/embroidery-locations/:embroideryLocationId',
      element: <EmbroideryLocations />,
      auth: ["addEmbroideryLocation", "editEmbroideryLocation"],
    },
    {
      path: 'embroidery-locations',
      element: <Navigate to="embroidery-locations" />,
    },
  ],
};

export default EmbroideryLocationAppConfig;
