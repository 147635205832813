import CmsConfigs from './cms/cmsConfigs';
import WorkConfigs from './work/workConfigs';
import LogsConfigs from './logs/logsConfigs';
import errorPagesConfig from './error/errorPagesConfig';
import DashboardAppConfig from './dashboard/dashboardAppConfig';
import SettingConfigs from './setting/settingConfigs';
import HRConfigs from './hr/hrConfigs';



const MainConfigs = [
  ...CmsConfigs,
  ...WorkConfigs,
  ...LogsConfigs,
  DashboardAppConfig,
  errorPagesConfig,
  ...SettingConfigs,
  ...HRConfigs
];

export default MainConfigs;
