import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Quotation = lazy(() => import('./quotation/Quotation'));
const Quotations = lazy(() => import('./quotations/Quotations'));


const QuotationAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'quotations',
      element: <Quotations />,
      auth: ["viewQuotation"],
    },
    {
      path: 'quotations/:quotationId/*',
      element: <Quotation />,
      auth: ["detailQuotation"],
    },
    {
      path: 'quotations',
      element: <Navigate to="quotations" />,
    },
  ],
};

export default QuotationAppConfig;
