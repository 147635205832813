import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Faqs = lazy(() => import('./faqs/faqs/Faqs'));

const FaqAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'faq',
      element: <Faqs />,
      auth: ["viewFAQ"]
    },
    {
      path: 'faq/:faqId',
      element: <Faqs />,
      auth: ["addFAQ", "editFAQ"],
    },
    {
      path: 'faq',
      element: <Navigate to="tag" />,
    },
  ],
};

export default FaqAppConfig;
