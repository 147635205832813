import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Leave = lazy(() => import('./leave/Leave'));
const Leaves = lazy(() => import('./leaves/Leaves'));
const LeaveView = lazy(() => import('./leaveView/LeaveView'));



const LeaveAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'leave',
      element: <Leaves />,
      // auth: ["viewLeave"],
    },
    {
      path: 'leave/:leaveId/*',
      element: <Leave />,
      // auth: ["addLeave", "editLeave"],
    },
    {
      path: 'leave/view/:leaveId/:dayId',
      element: <LeaveView />,
      // auth: ["viewLeaveView"],
    },
    {
      path: 'leave',
      element: <Navigate to="leave" />,
    },
  ],
};

export default LeaveAppConfig;
