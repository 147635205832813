import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const PatchTypes = lazy(() => import('./patchTypes/PatchTypes'));

const PatchTypeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/patch-types',
      element: <PatchTypes />,
      auth: ["viewPatchType"]
    },
    {
      path: 'setting/patch-types/:patchTypeId',
      element: <PatchTypes />,
      auth: ["addPatchType", "editPatchType"],
    },
    {
      path: 'patch-types',
      element: <Navigate to="patch-types" />,
    },
  ],
};

export default PatchTypeAppConfig;
