import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Reviews = lazy(() => import('./activities/Activities'));


const ActivityAppConfig = {
  settings: {
    layout: {},
  },
  auth: ["viewActivityLog"],
  routes: [
    {
      path: 'logs/activity',
      element: <Reviews />,
    },
    {
      path: 'logs/activity',
      element: <Navigate to="activity" />,
    },
  ],
};

export default ActivityAppConfig;
