import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Plan = lazy(() => import('./plan/Plan'));
const Plans = lazy(() => import('./plans/Plans'));


const PlanAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'subscription-plan',
      element: <Plans />,
      auth: ["viewSubscriptionPlan"],
    },
    {
      path: 'subscription-plan/:planId/*',
      element: <Plan />,
      auth: ["addSubscriptionPlan", "editSubscriptionPlan"],
    },
    {
      path: 'subscription-plan',
      element: <Navigate to="subscription-plan" />,
    },
  ],
};

export default PlanAppConfig;
