import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import MainConfigs from '../main/mainConfigs';
import ForgotPasswordConfig from '../main/authPages/forgot-password/ForgotPasswordConfig';
import ResetPasswordConfig from '../main/authPages/reset-password/ResetPasswordConfig';


const routeConfigs = [
  ...MainConfigs,
  SignOutConfig,
  SignInConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="dashboard/admin" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="/error/404" />,
  },
];

export default routes;
