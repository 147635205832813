import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const WhitelistIPs = lazy(() => import('./whitelistIPs/WhitelistIPs'));

const WhitelistIPAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/whitelist-ip',
      element: <WhitelistIPs />,
      auth: ["viewWhitelistIP"]
    },
    {
      path: 'setting/whitelist-ip/:whitelistIPId',
      element: <WhitelistIPs />,
      auth: ["addWhitelistIP", "editWhitelistIP"],
    },
    {
      path: 'whitelist-ip',
      element: <Navigate to="whitelist-ip" />,
    },
  ],
};

export default WhitelistIPAppConfig;
