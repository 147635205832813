import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Promotion = lazy(() => import('./promotion/Promotion'));
const Promotions = lazy(() => import('./promotions/Promotions'));



const PromotionAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'promotion',
      element: <Promotions />,
      // auth: ["viewPromotion"],
    },
    {
      path: 'promotion/:promotionId/*',
      element: <Promotion />,
      // auth: ["addPromotion", "editPromotion"],
    },
    {
      path: 'promotion',
      element: <Navigate to="promotion" />,
    },
  ],
};

export default PromotionAppConfig;
