import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const AddReview = lazy(() => import('./addReview/AddReview'));
const Reviews = lazy(() => import('./reviews/Reviews'));


const ReviewAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'review',
      element: <Reviews />,
      auth: ["viewReview"],
    },
    {
      path: 'review/:reviewId/*',
      element: <AddReview />,
      auth: ["addReview", "editReview"],
    },
    {
      path: 'review',
      element: <Navigate to="review" />,
    },
  ],
};

export default ReviewAppConfig;
