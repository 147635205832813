import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const AddContentSlider = lazy(() => import('./addContentSlider/AddContentSlider'));
const ContentSliders = lazy(() => import('./contentSliders/ContentSliders'));


const ContentSliderAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'content-slider',
      element: <ContentSliders />,
      auth: ["viewContentSlider"],
    },
    {
      path: 'content-slider/:contentSliderId/*',
      element: <AddContentSlider />,
      auth: ["addContentSlider", "editContentSlider"],
    },
    {
      path: 'content-slider',
      element: <Navigate to="content-slider" />,
    },
  ],
};

export default ContentSliderAppConfig;
