import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Borders = lazy(() => import('./borders/Borders'));

const BorderAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/borders',
      element: <Borders />,
      auth: ["viewBorder"]
    },
    {
      path: 'setting/borders/:borderId',
      element: <Borders />,
      auth: ["addBorder", "editBorder"],
    },
    {
      path: 'borders',
      element: <Navigate to="borders" />,
    },
  ],
};

export default BorderAppConfig;
