import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Backings = lazy(() => import('./backings/Backings'));

const BackingAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/backings',
      element: <Backings />,
      auth: ["viewBacking"]
    },
    {
      path: 'setting/backings/:backingId',
      element: <Backings />,
      auth: ["addBacking", "editBacking"],
    },
    {
      path: 'backings',
      element: <Navigate to="backings" />,
    },
  ],
};

export default BackingAppConfig;
