import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const SocialMediaChannels = lazy(() => import('./socialMediaChannels/SocialMediaChannels'));

const SocialMediaChannelAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/social-media-channels',
      element: <SocialMediaChannels />,
      auth: ["viewSocialMediaChannel"]
    },
    {
      path: 'setting/social-media-channels/:socialMediaChannelId',
      element: <SocialMediaChannels />,
      auth: ["addSocialMediaChannel", "editSocialMediaChannel"],
    },
    {
      path: 'social-media-channels',
      element: <SocialMediaChannels to="social-media-channels" />,
    },
  ],
};

export default SocialMediaChannelAppConfig;
