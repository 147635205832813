import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Job = lazy(() => import('./job/Job'));
const Jobs = lazy(() => import('./jobs/Jobs'));
const Applications = lazy(() => import('./applications/Applications'));



const JobAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'job',
      element: <Jobs />,
      auth: ["viewJob"],
    },
    {
      path: 'job/:jobId/*',
      element: <Job />,
      auth: ["addJob", "editJob"],
    },
    {
      path: 'job/application/:jobId/*',
      element: <Applications />,
      auth: ["viewJobApplications"],
    },
    {
      path: 'job',
      element: <Navigate to="job" />,
    },
  ],
};

export default JobAppConfig;
