import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ColorModels = lazy(() => import('./colorModels/ColorModels'));

const ColorModelAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/color-models',
      element: <ColorModels />,
      auth: ["viewColorModel"]
    },
    {
      path: 'setting/color-models/:colorModelId',
      element: <ColorModels />,
      auth: ["addColorModel", "editColorModel"],
    },
    {
      path: 'color-models',
      element: <Navigate to="color-models" />,
    },
  ],
};

export default ColorModelAppConfig;
