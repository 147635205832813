import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AddBannerSlider = lazy(() => import('./addBannerSlider/AddBannerSlider'));
const BannerSliders = lazy(() => import('./bannerSliders/BannerSliders'));


const BannerAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'banner',
      element: <BannerSliders />,
      auth: ["viewBannerSlider"],
    },
    {
      path: 'banner/:bannerSliderId/*',
      element: <AddBannerSlider />,
      auth: ["addBannerSlider", "editBannerSlider"],
    },
    {
      path: 'banner',
      element: <Navigate to="banner" />,
    },
  ],
};

export default BannerAppConfig;
