import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const EmployeeTypes = lazy(() => import('./employeeTypes/EmployeeTypes'));

const EmployeeTypeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/employee-type',
      element: <EmployeeTypes />,
      auth: ["viewEmployeeType"]
    },
    {
      path: 'setting/employee-type/:employeeTypeId',
      element: <EmployeeTypes />,
      auth: ["addEmployeeType", "editEmployeeType"],
    },
    {
      path: 'employee-type',
      element: <Navigate to="employee-type" />,
    },
  ],
};

export default EmployeeTypeAppConfig;
