import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AddCategory = lazy(() => import('./category/addCategory/AddCategory'));
const Category = lazy(() => import('./category/category/Category'));
const AddTag = lazy(() => import('./tags/addTag/AddTag'));
const Tags = lazy(() => import('./tags/tags/Tags'));
const AddBlog = lazy(() => import('./blog/addBlog/AddBlog'));
const Blogs = lazy(() => import('./blog/blogs/Blogs'));

const BlogAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'blog/category',
      element: <Category />,
      auth: ["viewBlogCategory"],
    },
    {
      path: 'blog/category/:categoryId/*',
      element: <AddCategory />,
      auth: ["addBlogCategory", "editBlogCategory"],
    },
    {
      path: 'blog/tag',
      element: <Tags />,
      auth: ["viewBlogTag"],
    },
    {
      path: 'blog/tag/:tagId/*',
      element: <AddTag />,
      auth: ["editBlogTag", "editBlogTag"],
    },
    {
      path: 'blog/all',
      element: <Blogs />,
      auth: ["viewBlog"],
    },
    {
      path: 'blog/:blogId/*',
      element: <AddBlog />,
      auth: ["addBlog", "editBlog"],
    },
    {
      path: 'blog/category',
      element: <Navigate to="category" />,
    },
  ],
};

export default BlogAppConfig;
