import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const WarningTypes = lazy(() => import('./warningTypes/WarningTypes'));


const WarningTypeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/warning-type',
      element: <WarningTypes />,
      // auth: ["viewWarningType"]
    },
    {
      path: 'setting/warning-type/:warningTypeId',
      element: <WarningTypes />,
      // auth: ["addWarningType", "editWarningType"],
    },
    {
      path: 'warning-type',
      element: <Navigate to="warning-type" />,
    },
  ],
};

export default WarningTypeAppConfig;
