import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Invoice = lazy(() => import('./invoice/Invoice'));
const Invoices = lazy(() => import('./invoices/Invoices'));


const InvoiceAppConfig = {
  settings: {
    layout: {},
  },
  auth: ["viewInvoice"],
  routes: [
    {
      path: 'invoices',
      element: <Invoices />,
    },
    {
      path: 'invoices/:invoiceId/*',
      element: <Invoice />,
      // auth: ["detailInvoice"],
    },
    {
      path: 'invoices',
      element: <Navigate to="invoices" />,
    },
  ],
};

export default InvoiceAppConfig;
