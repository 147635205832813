import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const SalaryTypes = lazy(() => import('./salaryTypes/SalaryTypes'));

const SalaryTypeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/salary-type',
      element: <SalaryTypes />,
      auth: ["viewSalaryType"]
    },
    {
      path: 'setting/salary-type/:salaryTypeId',
      element: <SalaryTypes />,
      auth: ["addSalaryType", "editSalaryType"],
    },
    {
      path: 'salary-type',
      element: <Navigate to="salary-type" />,
    },
  ],
};

export default SalaryTypeAppConfig;
