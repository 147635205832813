import ReturnTimeAppConfig from "./returnTime/ReturnTimeAppConfig";
import ColorModelAppConfig from "./colorModel/ColorModelAppConfig";
import EmbroideryLocationAppConfig from "./embroideryLocation/EmbroideryLocationAppConfig";
import SocialMediaChannelAppConfig from "./socialMediaChannel/SocialMediaChannelAppConfig";
import BrandingAppConfig from "./branding/BrandingAppConfig";
import BorderAppConfig from "./border/BorderAppConfig";
import BackingAppConfig from "./backing/BackingAppConfig";
import PatchTypeAppConfig from "./patchType/PatchTypeAppConfig";
import PatchEmbroideryAppConfig from "./patchEmbroidery/PatchEmbroideryAppConfig";
import TurnaroundTimeAppConfig from "./turnaroundTime/TurnaroundTimeAppConfig";
import UnitAppConfig from "./unit/UnitAppConfig";
import ShippingAppConfig from "./shipping/ShippingAppConfig";


const OrderSettingsConfig = [
  ReturnTimeAppConfig,
  ColorModelAppConfig,
  EmbroideryLocationAppConfig,
  SocialMediaChannelAppConfig,
  BrandingAppConfig,
  BorderAppConfig,
  BackingAppConfig,
  PatchTypeAppConfig,
  PatchEmbroideryAppConfig,
  TurnaroundTimeAppConfig,
  UnitAppConfig,
  ShippingAppConfig
];

export default OrderSettingsConfig;
