import GalleryAppConfig from './gallery/GalleryAppConfig';
import BlogAppConfig from './blog/blogAppConfig';
import FreeDesignAppConfig from './freeDesign/freeDesignAppConfig';
import FaqAppConfig from './faq/faqAppConfig';
import ReviewAppConfig from './review/reviewAppConfig';
import ContentSliderAppConfig from './contentSlider/contentSliderAppConfig';
import BannerAppConfig from './banner/BannerAppConfig';
import CustomersAppConfig from './customers/customersAppConfig';
import CouponAppConfig from './coupon/couponAppConfig';
import PricingAppConfig from './pricing/pricingAppConfig';
import InvoiceAppConfig from './invoice/invoiceAppConfig';
import PlanAppConfig from './plan/planAppConfig';
import PlanHistoryAppConfig from './planHistory/planHistoryAppConfig';



const CmsConfigs = [
  GalleryAppConfig,
  BlogAppConfig,
  FreeDesignAppConfig,
  FaqAppConfig,
  ReviewAppConfig,
  ContentSliderAppConfig,
  BannerAppConfig,
  CustomersAppConfig,
  CouponAppConfig,
  PricingAppConfig,
  InvoiceAppConfig,
  PlanAppConfig,
  PlanHistoryAppConfig
];

export default CmsConfigs;
