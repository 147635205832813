import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const EmployeeShift = lazy(() => import('./employeeShift/EmployeeShift'));
const EmployeeShifts = lazy(() => import('./employeeShifts/EmployeeShifts'));



const EmployeeShiftAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/employee-shift',
      element: <EmployeeShifts />,
      // auth: ["viewEmployeeShift"],
    },
    {
      path: 'setting/employee-shift/:employeeShiftId/*',
      element: <EmployeeShift />,
      // auth: ["addEmployeeShift", "editEmployeeShift"],
    },
    {
      path: 'employee-shift',
      element: <Navigate to="employee-shift" />,
    },
  ],
};

export default EmployeeShiftAppConfig;
