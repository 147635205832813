import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Wallet = lazy(() => import('./wallet/Wallet'));
const Customers = lazy(() => import('./customers/Customers'));


const CustomersAppConfig = {
  settings: {
    layout: {},
  },
  auth: ["viewCustomer"],
  routes: [
    {
      path: 'customers',
      element: <Customers />,
    },
    {
      path: 'customers/:customerId',
      element: <Wallet />,
    },
    {
      path: 'customers',
      element: <Navigate to="customers" />,
    },
  ],
};

export default CustomersAppConfig;
