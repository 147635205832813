import QuotationsAppConfig from "./quotation/quotationsAppConfig";
import OrdersAppConfig from "./order/ordersAppConfig";


const WorkConfigs = [
  QuotationsAppConfig,
  OrdersAppConfig,
];

export default WorkConfigs;
