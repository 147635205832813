import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Coupon = lazy(() => import('./coupon/Coupon'));
const Coupons = lazy(() => import('./coupons/Coupons'));


const CouponAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'coupon',
      element: <Coupons />,
      auth: ["viewCoupon"],
    },
    {
      path: 'coupon/:couponId/*',
      element: <Coupon />,
      auth: ["addCoupon", "editCoupon"],
    },
    {
      path: 'coupon',
      element: <Navigate to="coupon" />,
    },
  ],
};

export default CouponAppConfig;
