import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const PlanHistory = lazy(() => import('./planHistory/PlanHistory'));
const Subscriptions = lazy(() => import('./subscriptions/Subscriptions'));



const PlanHistoryAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'subscription',
      element: <Subscriptions />,
      auth: ["viewSubscription"],
    },
    {
      path: 'subscription/:subscriptionId',
      element: <PlanHistory />,
      auth: ["historySubscription"],
    },
    {
      path: 'subscription',
      element: <Navigate to="subscription" />,
    },
  ],
};

export default PlanHistoryAppConfig;
