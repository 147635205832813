import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Shippings = lazy(() => import('./shippings/Shippings'));

const ShippingAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/shippings',
      element: <Shippings />,
      auth: ["viewShippingCompany"]
    },
    {
      path: 'setting/shippings/:shippingId',
      element: <Shippings />,
      auth: ["addShippingCompany", "editShippingCompany"],
    },
    {
      path: 'shippings',
      element: <Navigate to="shippings" />,
    },
  ],
};

export default ShippingAppConfig;
