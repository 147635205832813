import EmployeeTypeAppConfig from "./employeeType/EmployeeTypeAppConfig";
import SalaryTypeAppConfig from "./salaryType/SalaryTypeAppConfig";
import WhitelistIPAppConfig from "./whitelistIP/whitelistIPAppConfig";
import EmployeeShiftAppConfig from "./employeeShift/EmployeeShiftAppConfig";
import WarningTypeAppConfig from "./warningType/WarningTypeAppConfig";
import LeaveTypeAppConfig from "./leaveType/LeaveTypeAppConfig";


const BasicSettingsConfigs = [
  EmployeeTypeAppConfig,
  SalaryTypeAppConfig,
  WhitelistIPAppConfig,
  EmployeeShiftAppConfig,
  WarningTypeAppConfig,
  LeaveTypeAppConfig
];

export default BasicSettingsConfigs;
