import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Brandings = lazy(() => import('./brandings/Brandings'));

const BrandingAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/brandings',
      element: <Brandings />,
      auth: ["viewBacking"]
    },
    {
      path: 'setting/brandings/:brandingId',
      element: <Brandings />,
      auth: ["addBacking", "editBacking"],
    },
    {
      path: 'brandings',
      element: <Navigate to="brandings" />,
    },
  ],
};

export default BrandingAppConfig;
