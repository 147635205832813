import JobAppConfig from "./job/jobAppConfig";
import EmployeeAppConfig from "./employee/employeeAppConfig";
import DepartmentAppConfig from "./department/DepartmentAppConfig";
import DesignationAppConfig from "./designation/DesignationAppConfig";
import WarningAppConfig from "./warning/warningAppConfig";
import PromotionAppConfig from "./promotion/promotionAppConfig";
import LeaveAppConfig from "./leave/leaveAppConfig";

const HRConfigs = [
  JobAppConfig,
  EmployeeAppConfig,
  DepartmentAppConfig,
  DesignationAppConfig,
  WarningAppConfig,
  PromotionAppConfig,
  LeaveAppConfig,
];

export default HRConfigs;
