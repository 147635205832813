import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Units = lazy(() => import('./units/Units'));

const UnitAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/units',
      element: <Units />,
      auth: ["viewUnit"]
    },
    {
      path: 'setting/units/:unitId',
      element: <Units />,
      auth: ["addUnit", "editUnit"],
    },
    {
      path: 'units',
      element: <Navigate to="units" />,
    },
  ],
};

export default UnitAppConfig;
