import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const Order = lazy(() => import('./order/Order'));
const Orders = lazy(() => import('./orders/Orders'));
const OrderCreate = lazy(() => import('./orderCreate/OrderCreate'));


const OrdersAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'orders',
      element: <Orders />,
      auth: ["viewOrder"],
    },
    {
      path: 'orders/:orderId',
      element: <Order />,
      auth: ["detailOrder"],
    },
    {
      path: 'orders/create',
      element: <OrderCreate />,
      auth: ["addOrder"],
    },
    {
      path: 'orders/edit/:orderCreateId',
      element: <OrderCreate />,
      auth: ["editOrder"],
    },
    {
      path: 'orders',
      element: <Navigate to="orders" />,
    },
  ],
};

export default OrdersAppConfig;
