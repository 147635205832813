import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AddCategory = lazy(() => import('./category/addCategory/AddCategory'));
const Category = lazy(() => import('./category/category/Category'));
const AddTag = lazy(() => import('./tags/addTag/AddTag'));
const Tags = lazy(() => import('./tags/tags/Tags'));
// const AddSubCategory = lazy(() => import('./subCategory/addSubCategory/AddSubCategory'));
// const SubCategories = lazy(() => import('./subCategory/subCategories/SubCategories'));
const AddFreeDesign = lazy(() => import('./allFreeDesign/addFreeDesign/AddFreeDesign'));
const FreeDesigns = lazy(() => import('./allFreeDesign/freeDesigns/FreeDesigns'));


const FreeDesignAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'free-design/category',
      element: <Category />,
      auth: ["viewFreeDesignCategory"],
    },
    {
      path: 'free-design/category/:categoryId/*',
      element: <AddCategory />,
      auth: ["addFreeDesignCategory", "editFreeDesignCategory"],
    },
    {
      path: 'free-design/tag',
      element: <Tags />,
      auth: ["viewFreeDesignTag"],
    },
    {
      path: 'free-design/tag/:tagId/*',
      element: <AddTag />,
      auth: ["addFreeDesignTag", "editFreeDesignTag"],
    },
    // {
    //   path: 'free-design/sub-category',
    //   element: <SubCategories />,
    //   auth: ["viewFreeDesignSubCategory"],
    // },
    // {
    //   path: 'free-design/sub-category/:subCategoryId/*',
    //   element: <AddSubCategory />,
    //   auth: ["editFreeDesignSubCategory", "editFreeDesignSubCategory"],
    // },
    {
      path: 'free-design/all',
      element: <FreeDesigns />,
      auth: ["viewFreeDesign"],
    },
    {
      path: 'free-design/:freeDesignId/*',
      element: <AddFreeDesign />,
      auth: ["addFreeDesign", "editFreeDesign"],
    },
    {
      path: 'free-design/category',
      element: <Navigate to="category" />,
    },
  ],
};

export default FreeDesignAppConfig;
