import { lazy } from 'react';
import { Navigate } from 'react-router-dom';


const LeaveType = lazy(() => import('./leaveType/LeaveType'));
const LeavesType = lazy(() => import('./leavesType/LeavesType'));



const LeaveTypeAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'setting/leave-type',
      element: <LeavesType />,
      // auth: ["viewLeaveType"],
    },
    {
      path: 'setting/leave-type/:leaveTypeId/*',
      element: <LeaveType />,
      // auth: ["addLeaveType", "editLeaveType"],
    },
    {
      path: 'leave-type',
      element: <Navigate to="leave-type" />,
    },
  ],
};

export default LeaveTypeAppConfig;
